@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=DM+Serif+Display:ital@0;1&family=Fjalla+One&family=Instrument+Serif:ital@0;1&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');


@font-face {
  font-family: "seasons";
  src: url('https://dm90w54v7z2d.cloudfront.net/seasons.woff2');
  font-style: normal;
}


.seasons{
    font-family: "seasons";
}


.yeseva{
    font-family: 'Yeseva One', serif;
}

.text-primary-600 {
  color: #2563eb;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hover-float:hover {
  animation: float 2s ease-in-out infinite;
}

.bg-gold-leaf {
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d4af37' fill-opacity='0.1'%3E%3Cpath d='M56 54v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-50V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 54v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
}

/* Add or update these styles */
html {
  scroll-behavior: smooth;
}

/* Optional: Add a subtle gradient background */
.gradient-bg {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(249, 250, 251, 1)
  );
}

/* Smooth image transitions */
img {
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Remove scrollbar but keep functionality */
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Add these new styles */
.parallax-text {
  mix-blend-mode: difference;
}

.image-mask {
  mask-image: linear-gradient(to bottom, 
    rgba(0,0,0,1) 0%,
    rgba(0,0,0,1) 60%,
    rgba(0,0,0,0) 100%
  );
  -webkit-mask-image: linear-gradient(to bottom, 
    rgba(0,0,0,1) 0%,
    rgba(0,0,0,1) 60%,
    rgba(0,0,0,0) 100%
  );
}

/* Smooth transitions */
.smooth-transform {
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}